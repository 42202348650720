export const text = {
  label: 'Texto',
  value: 'default',
  description: 'Texto simples',
}

export const number = {
  label: 'Número',
  value: 'number-pad',
  description: 'Somente valores númericos',
}

export default {
  text,
  number,
}
