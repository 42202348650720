<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <span class="title">Campo calculado, insira a regra abaixo.</span>
        <div class="formula-input" @click="focusInput">
          <span v-for="(part, index) in formulaParts" :key="index">
            <span v-if="!part.isChip">{{ part.text }}</span>
            <v-chip
              v-else
              outlined
              color="primary"
              small
              close
              @click:close="removeChip(index)"
            >
              {{ part.text }}
            </v-chip>
          </span>
          <input
            ref="input"
            v-model="currentInput"
            type="text"
            hint="Insira a fórmula, ao finalizar pressione Enter ou Espaço"
            @keyup.enter="addCurrentInput"
            @keyup.space="addCurrentInput"
            @keydown.backspace="handleBackspace"
          />
        </div>
        <span class="caption"
          >Insira a fórmula, ao finalizar pressione Enter ou Espaço.</span
        >
      </v-col>

      <v-col cols="12">
        <v-chip-group>
          <v-chip
            v-for="(item, key) in fields"
            :key="key"
            outlined
            color="secondary"
            @click="addChip(item)"
          >
            {{ item.label }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { LOAD_ALL_FIELDS } from '../../../../vuex/modules/structure'
export default {
  name: 'Calculate',

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      formulaParts: [],
      currentInput: '',

      fields: [],

      isCalculatedField: false,
    }
  },

  computed: {},

  watch: {
    currentInput() {},

    formulaParts() {
      const formulate = this.createFormula()

      this.$emit('input', formulate)
    },
  },

  created() {
    this.getFields().then(() => {
      if (this.value) {
        const parts = this.value
          .replace(/\{(@\w+)}|\(|\)/g, ' $& ')
          .split(' ')
          .filter(part => part.trim())

        this.formulaParts = parts.map(part => {
          if (part[0] === '{') {
            const key = part.slice(2, -1)
            const field = this.fields.find(field => field.name === key)

            return { isChip: true, text: field.label, key }
          }

          return { isChip: false, text: part }
        })
      }
    })
  },

  methods: {
    ...mapActions('structure', [LOAD_ALL_FIELDS]),

    async getFields() {
      const fields = await this.LOAD_ALL_FIELDS()

      this.fields = fields.filter(field => field.componentType == 'inputText')
    },

    focusInput() {
      this.$refs.input.focus()
    },
    addChip(item) {
      this.addCurrentInput()
      this.formulaParts.push({ isChip: true, text: item.label, key: item.name })
      this.focusInput()
    },
    addCurrentInput() {
      if (this.currentInput.trim()) {
        this.formulaParts.push({
          isChip: false,
          text: this.currentInput.trim(),
        })
        this.currentInput = ''
      }
    },
    removeChip(index) {
      this.formulaParts.splice(index, 1)
    },
    handleBackspace() {
      if (this.currentInput === '' && this.formulaParts.length > 0) {
        this.formulaParts.pop()
      }
    },
    createFormula() {
      let formula = this.formulaParts
        .map(part => (part.isChip ? `{@${part.key}}` : part.text))
        .join('')

      if (formula[0] !== '(') {
        formula = `(${formula}`
      }

      if (formula[formula.length - 1] !== ')') {
        formula = `${formula})`
      }

      return formula
    },
  },
}
</script>

<style scoped>
.formula-input {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 12px;
  border-radius: 4px;
  min-height: 56px;
  cursor: text;
}
.formula-input input {
  border: none;
  outline: none;
  flex: 1;
  min-width: 120px;
}
</style>
