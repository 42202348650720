import { incrementBy } from './functions'
import { brazilianReal, percentage, realNumber, timePicker } from './masks'
import {
  calculatedField,
  defaultValue,
  dependencies,
  dynamicText,
  futureDate,
  hidden,
  lessThan,
  max,
  maxDate,
  maxLength,
  min,
  minDate,
  minimumTimePiker,
  minLength,
  multiple,
  nullValue,
  required,
  requiredByOtherField,
  requiredOnlyNotConfirm,
  reverseConform,
  size,
  subFields,
} from './rules'
import { number, text } from './types'

export default [
  {
    label: 'Caixa de texto simples',
    componentType: 'inputText',
    description: 'Campo de texto simples',
    placeholder: true,
    types: [text, number],
    masks: [realNumber, brazilianReal, percentage, timePicker],

    rules: [
      minLength,
      maxLength,
      size,
      lessThan,
      dependencies,
      multiple,
      required,
      nullValue,
      defaultValue,
      hidden,
    ],

    functions: [incrementBy],
  },
  {
    label: 'Campo de texto simples',
    componentType: 'text',
    placeholder: false,
    description: 'Campo de texto simples ou campo calculado',
    rules: [calculatedField, hidden],
  },
  {
    label: 'Checkbox',
    placeholder: false,
    description: 'Campo de seleção',
    componentType: 'checkbox',
    rules: [required],
  },
  {
    label: 'Área de texto',
    placeholder: true,
    componentType: 'textArea',
    description: 'Campo de texto com várias linhas',
    rules: [required, maxLength],
  },
  {
    componentType: 'timePiker',
    placeholder: true,
    label: 'Seletor de hora',
    description: 'Seletor de hora e minutos',
    rules: [required, minimumTimePiker],
  },
  {
    componentType: 'datePiker',
    placeholder: true,
    label: 'Seletor de data',
    description: 'Seletor de data',
    rules: [required, minDate, maxDate, futureDate],
  },
  {
    componentType: 'confirmGroup',
    placeholder: false,
    label: 'Conforme grupo',
    description: 'Campo de seleção de conformidade ou não conformidade',
    rules: [required, requiredOnlyNotConfirm, reverseConform],
    subFields: true,
  },
  {
    componentType: 'btnAssinatura',
    placeholder: false,
    label: 'Assinatura',
    description: 'Campo de assinatura',
    rules: [required],
  },
  {
    componentType: 'multipleImageSelect',
    placeholder: true,
    label: 'Seleção de imagens',
    description: 'Seleção de imagens',
    rules: [required, max, min],
    type: 'multipleImageSelect',
  },
  {
    componentType: 'dynamicText',
    placeholder: false,
    label: 'Texto dinâmico',
    description: 'Texto dinâmico',
    rules: [dynamicText],
  },
  {
    componentType: 'groupField',
    placeholder: false,
    label: 'Grupo de campos',
    description: 'Grupo de campos',
    subFields: true,
    rules: [required],
  },
  {
    componentType: 'groupCheckbox',
    placeholder: false,
    label: 'Grupo de checkbox',
    description: 'Checkbox com suporte a subitens',
    subFields: true,
    rules: [required, requiredByOtherField],
  },
  {
    componentType: 'someCheckbox',
    placeholder: false,
    label: 'Checkbox seletor',
    description: 'Seletor somente de caixas de seleção',
    subFields: true,
    rules: [min, max, required],

    properties: {
      components: ['checkbox'],
    },
  },
  // {
  //   componentType: 'select',
  //   label: 'Seletor de opções',
  //   description: 'Seletor de opções',
  //   rules: [required],
  // },
  {
    componentType: 'selection',
    placeholder: false,
    label: 'Seleção',
    description: 'Seleção',
    subFields: true,
    properties: {
      components: ['buttonSelect'],
    },
    rules: [required],
  },
  {
    componentType: 'buttonSelect',
    placeholder: false,
    label: 'Botão de seleção',
    description: 'Botão de seleção',
    rules: [required, subFields],
  },
  {
    componentType: 'multiInsertForm',
    label: 'Formulário de inserção múltipla',
    description: 'Formulário de inserção múltipla',
    subFields: true,
    rules: [required],
  },
  {
    componentType: 'groupRadioButton',
    placeholder: false,
    label: 'Grupo de radio button',
    description: 'Grupo de radio button, seleção condicional de opções',
    subFields: true,
    subFields1: true,
    rules: [required],
  },
  {
    componentType: 'onlySelectionGroup',
    placeholder: false,
    label: 'Grupo de seleção única',
    description: 'Grupo de seleção única',
    rules: [required],
  },
  {
    componentType: 'multiSelectionGroup',
    placeholder: false,
    label: 'Grupo de seleção múltipla',
    description: 'Grupo de seleção múltipla',
    rules: [required],
  },
  {
    componentType: 'yearPicker',
    label: 'Seletor de ano',
    description: 'Seletor de ano',
    rules: [
      required,
      {
        key: 'min',
        label: 'Ano de início',
        description: 'Selecione um ano de inicio',
        type: 'inputSelect',
      },
      {
        key: 'requiredChildrens',
        label: 'Requerido por outro campo',
        description:
          'Caso o campo dependente seja preenchido, este campo é obrigatório',
        type: 'inputSelect',
      },
    ],
  },
]
