<template>
  <v-row>
    <only-selection-group
      v-if="
        component.componentType == 'multiSelectionGroup' ||
        component.componentType == 'onlySelectionGroup'
      "
      v-model="form"
      :component="component"
    />
  </v-row>
</template>

<script>
import OnlySelectionGroup from './OnlySelectionGroup.vue'

export default {
  name: 'Components',

  emits: ['input'],

  components: {
    OnlySelectionGroup,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    component: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.value,
    }
  },

  watch: {
    values: {
      handler() {
        this.$emit('input', this.form)
      },

      deep: true,
    },
  },
}
</script>
