export const required = {
  label: 'Obrigatório',
  description: 'Campo obrigatório',
  key: 'required',
  type: 'checkbox',
  cols: 12,
}

export const minLength = {
  label: 'Mínimo de caracteres',
  description: 'Mínimo de caracteres',
  key: 'minLength',
  type: 'number',
  required: false,
}

export const maxLength = {
  label: 'Máximo de caracteres',
  description: 'Máximo de caracteres',
  key: 'maxLength',
  type: 'number',
  required: false,
}

export const size = {
  label: 'Valor máximo',
  description: 'Valor máximo. Exemplo: 100 o valor não pode ser maior que 100',
  key: 'size',
  type: 'number',
  required: false,
}

export const nullValue = {
  label: 'Valor nulo',
  description: 'O campo não pode ser nulo',
  key: 'nullable',
  type: 'checkbox',
  cols: 12,
}

export const minDate = {
  label: 'Data mínima',
  description: 'Data mínima',
  key: 'minDate',
  type: 'date',
}
export const maxDate = {
  label: 'Data máxima',
  description: 'Data máxima',
  key: 'maxDate',
  type: 'date',
}

export const futureDate = {
  label: 'Permitir data futura',
  description: 'Permitir data futura',
  key: 'permitirDataFutura',
  type: 'checkbox',
  cols: 12,
}

export const notAllowPreviousDate = {
  label: 'Não permitir data anterior',
  description: 'Não permitir data anterior',
  key: 'naoPermitirDataAnterior',
  type: 'checkbox',
  cols: 12,
}

export const calculatedField = {
  label: 'Campo calculado',
  description: 'Campo calculado',
  key: 'fieldCalculated',
  type: 'input',
  cols: 12,
}

export const editable = {
  label: 'Campo editável',
  description: 'Campo editável',
  key: 'editable',
  type: 'checkbox',
  cols: 12,
}

export const minimumDate = {
  label: 'Data mínima',
  description: 'Data mínima',
  key: 'minimumDate',
  type: 'inputSelect',
}

export const minimumTime = {
  label: 'Hora mínima',
  description: 'Hora mínima',
  key: 'minimumTime',
  type: 'inputSelect',
}

export const requiredChildrens = {
  label: 'Campos filhos obrigatórios',
  description: 'Caso marcado, todos os campos filhos são obrigatórios',
  key: 'requiredChildrens',
  type: 'checkbox',
  cols: 12,
  enabled: ['groupField', 'groupCheckbox'],
}

export const requiredOnlyNotConfirm = {
  label: 'Obrigatório apenas se não confirmar',
  description: 'O campo é obrigatório apenas se não confirmar',
  key: 'requiredOnlyNotConfirm',
  type: 'checkbox',
  cols: 12,
}

export const max = {
  label: 'Quantidade máxima',
  description: 'Quantidade máxima',
  key: 'max',
  type: 'number',
}

export const min = {
  label: 'Quantidade mínima',
  description: 'Quantidade mínima',
  key: 'min',
  type: 'number',
}

export const associatedFields = {
  label: 'Campos associados',
  description: 'Campos associados',
  key: 'associatedFields',
  type: 'inputSelect',
}

export const requiredByOtherField = {
  label: 'Obrigatório por outro campo',
  description:
    'Obrigatório por outro campo, caso o campo associado seja preenchido o campo atual é obrigatório',
  key: 'requiredByOtherField',
  type: 'inputSelect',
}

export const requiredBy = {
  label: 'Obrigatório por outro campo',
  description:
    'Obrigatório por outro campo, caso o campo associado seja preenchido o campo atual é obrigatório',
  key: 'requiredBy',
  type: 'inputSelect',
}

export const requiredByOr = {
  label: 'Obrigatório por outro campo',
  description:
    'Obrigatório por outro campo, caso o campo associado seja preenchido o campo atual é obrigatório',
  key: 'requiredByOr',
  type: 'inputSelect',
}

export const requiredConfirmedAndUnmarked = {
  label: 'Obrigatório confirmado e desmarcado',
  description: 'Obrigatório confirmado e desmarcado',
  key: 'requiredConfirmedAndUnmarked',
  type: 'checkbox',
  cols: 12,
}

export const reverseConform = {
  label: 'Inverter conformidade',
  description: 'Inverter conformidade',
  key: 'reverseConform',
  type: 'checkbox',
  cols: 12,
}

export const dependencies = {
  label: 'Dependências',
  description: 'O campo depende de outros campos',
  key: 'dependencies',
  type: 'inputSelect',
  cols: 12,
  properties: {
    multiple: true,
  },
}

export const lessThan = {
  label: 'Menor que',
  description: 'O valor do campo deve ser menor que o valor do campo associado',
  key: 'lessThan',
  type: 'inputSelect',
  cols: 12,
  properties: {
    multiple: true,
    inputSelectType: 'numeric',
  },
}

export const multiple = {
  label: 'Múltiplo',
  description: 'O valor do campo deve ser múltiplo do valor do campo associado',
  key: 'multiple',
  type: 'inputSelect',
  cols: 12,
  properties: {
    multiple: true,
  },
}

export const minimumTimePiker = {
  label: 'Hora mínima',
  description: 'Hora mínima',
  key: 'minimumTimePiker',
  type: 'minimumTimePiker',
  cols: 12,
}

export const validation = {
  label: 'Validação',
  description: 'Validação',
  key: 'validation',
  type: 'inputSelect',

  properties: {
    tyeps: [
      {
        key: 'scheduleValidation',
        label: 'Validação de horário',

        fields: [
          {
            key: 'validateDate',
            field: true,
          },
          {
            key: 'validateTime',
            field: true,
          },
        ],
      },
    ],
  },
}

export const defaultPreenchimento = {
  key: 'defaultValue',
  label: 'Valor preenchido por padrão',
  description: 'Valor preenchido por padrão',
  hint: 'Insira o valor que será preenchido por padrão',
  type: 'input',
}

export const defaultValue = {
  key: 'defaultValue',
  label: 'Valor padrão',
  description: 'Valor padrão',
  type: 'inputOptions',
  options: [
    {
      key: 'priceForKm',
      label: 'Preço por km',
    },
    {
      key: 'area',
      label: 'Área da fazenda',
    },
    {
      key: 'nomeResponsavel',
      label: 'Nome do responsável',
    },
    {
      key: 'nomeProdutor',
      label: 'Nome do produtor',
    },
    {
      key: 'nomeConsultor',
      label: 'Nome do consultor',
    },
    {
      key: 'fazenda',
      label: 'Nome da fazenda',
    },
  ],
}

export const subFields = {
  label: 'Campos filhos',
  description:
    'Campos filhos, será exibido um botão para adicionar campos filhos',
  key: 'subFields',
  type: 'checkbox',
}

export const dynamicText = {
  label: 'Texto dinâmico',
  description: 'Texto dinâmico',
  key: 'text',
  type: 'textarea',
  cols: 12,
}

export const hidden = {
  label: 'Ocultar campo',
  description: 'Ocultar campo',
  key: 'hidden',
  type: 'checkbox',
  cols: 12,
}

export default {
  required,
  minLength,
  maxLength,
  size,
  nullValue,
  minDate,
  maxDate,
  futureDate,
  notAllowPreviousDate,
  calculatedField,
  editable,
  minimumDate,
  minimumTime,
  requiredOnlyNotConfirm,
  requiredChildrens,
  max,
  min,
  reverseConform,
  associatedFields,
  requiredByOtherField,
  requiredBy,
  requiredByOr,
  requiredConfirmedAndUnmarked,
  dependencies,
  lessThan,
  multiple,
  minimumTimePiker,
  validation,
  defaultValue,
  subFields,
  dynamicText,
  hidden,
}
