export const realNumber = {
  label: 'Número real',
  description: 'Exemplo: 1.5',
  value: 'numberReal',
}

export const timePicker = {
  label: 'Minuto',
  description: 'Exemplo: 1:30',
  value: 'minute',
}

export const brazilianReal = {
  label: 'BRL',
  description: 'Valor monetário, formato brasileiro',
  value: 'BRL',
}

export const percentage = {
  label: 'Porcentagem',
  description: 'Número real com %',
  value: 'percentage',
}

export default {
  realNumber,
  brazilianReal,
  percentage,
  timePicker,
}
