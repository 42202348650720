<template>
  <v-row>
    <v-col cols="12">
      <v-card-title>
        <h5>Opções</h5>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6" class="d-flex">
            <v-btn class="primary mr-3" @click="addOption">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-text-field
              v-model="optionName"
              placeholder="Digite o nome da opção"
              label="Nome da opção"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="(option, index) in form.options" :key="index" cols="12">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="option.label"
                  label="Nome"
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-btn icon @click="removeOption(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import Toast from '../../../../plugins/toast'

export default {
  name: 'OnlySelectionGroup',

  emits: ['input'],

  props: {
    value: {
      type: Object,
      required: true,
    },

    component: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.value,

      optionName: '',
    }
  },

  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },

      deep: true,
    },
  },

  created() {
    if (!this.form.options) {
      this.form.options = []
    }
  },

  methods: {
    removeOption(index) {
      this.form = {
        ...this.form,
        options: this.form.options.filter((_, i) => i !== index),
      }
    },

    addOption() {
      if (!this.optionName) {
        return Toast().fire({
          icon: 'error',
          title: 'Nome da opção é obrigatório',
        })
      }

      this.form.options.push({
        label: this.optionName,
        value: this.optionName,
        componentType: 'buttonSelect',
      })

      this.optionName = ''
    },
  },
}
</script>
