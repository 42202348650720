<template>
  <v-row>
    <v-col>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="600"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary darken-1 white--text"
            v-bind="attrs"
            :disabled="disabled"
            v-on="on"
          >
            <v-icon left>mdi-plus</v-icon>
            Novo relatório
          </v-btn>
        </template>
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Insira as informações do novo relatório.</v-toolbar
            >
            <v-card-text>
              <v-form id="form" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.title"
                      label="Titulo"
                      outlined
                      dense
                      maxlength="100"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="form.subtitle"
                      label="Subtitulo"
                      outlined
                      dense
                      maxlength="100"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox
                      v-model="form.active"
                      label="Ativo"
                      color="green"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="green" text @click="save">Criar</v-btn>
              <v-btn color="red" text @click="close">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CREATE_REPORT } from '../../../vuex/modules/structure'

export default {
  name: 'NewStructure',

  props: {
    disabled: Boolean,
  },

  data: () => ({
    dialog: false,

    form: {
      title: '',
      subtitle: '',
      active: true,
    },

    rules: {
      required: value => !!value || 'Campo obrigatório.',
    },
  }),

  computed: {
    ...mapState('structure', ['data', 'loading', 'project']),
  },

  methods: {
    ...mapActions('structure', [CREATE_REPORT]),

    save() {
      const valid = this.$refs.form.validate()

      if (!valid)
        return this.Toast().fire({
          icon: 'error',
          title: 'Por favor, preencha os campos obrigatórios.',
        })

      this.CREATE_REPORT(this.form)

      this.dialog = false
    },

    close() {
      this.dialog = false
      this.form = {
        title: '',
        subtitle: '',
        active: true,
      }
    },
  },
}
</script>
