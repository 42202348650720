<template>
  <v-row>
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="700"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip
            v-if="structureItem.type == 'form' || structureItem.fields"
            right
          >
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="primary darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
                @click="openDialogComponent"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Novo componente</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on: onDelete }">
              <v-btn
                icon
                color="red darken-1"
                v-on="onDelete"
                @click="deleteItem(structureItem)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Deletar componente</span>
          </v-tooltip>

          <v-tooltip v-if="structureItem.componentType" left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="yellow darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                @click="openUpdate"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Editar componente</span>
          </v-tooltip>

          <v-tooltip v-if="structureItem?.fields1" left>
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                icon
                color="success darken-1"
                v-bind="{ ...attrs, ...attrsTooltip }"
                v-on="{ ...on, ...onTooltip }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Novo componente de segundo subcampos</span>
          </v-tooltip>
        </template>
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Insira as informações do novo componente</v-toolbar
            >
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="component"
                    item-value="componentType"
                    :items="structureItems"
                    label="Selecione o componente"
                    filled
                    dense
                    @change="chosenComponent($event)"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.label }}
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.description
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-form v-if="component" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.title"
                      label="Título"
                      required
                      dense
                      filled
                      hint="Texto que aparecerá acima do campo"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="component?.placeholder" cols="12">
                    <v-text-field
                      v-model="form.placeholder"
                      label="Placeholder"
                      required
                      dense
                      filled
                      hint="Texto que aparecerá no campo antes do usuário digitar"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <Components v-model="form" :component="component" />
                  </v-col>
                  <v-divider></v-divider>
                  <v-col v-if="component.types" cols="12">
                    <v-divider horizontal></v-divider>
                    <v-card-title>Configurações</v-card-title>
                  </v-col>
                  <v-col v-if="component.types" cols="6">
                    <v-select
                      v-model="form.type"
                      :items="component.types"
                      :item-text="item => item.label"
                      :item-value="item => item.value"
                      label="Tipos"
                      required
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.label
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.description
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template></v-select
                    >
                  </v-col>
                  <v-col v-if="component.masks" cols="6">
                    <v-select
                      v-model="form.masks"
                      :items="component.masks"
                      :item-text="item => item.label"
                      :item-value="item => item.value"
                      label="Máscaras"
                      required
                      dense
                      clearable
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.label
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.description
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="component.rules" cols="12">
                    <v-divider horizontal></v-divider>
                    <v-card-title>Regras</v-card-title>
                  </v-col>
                  <v-col v-if="component.rules">
                    <Rules v-model="rules" :rules="component.rules" />
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-divider horizontal></v-divider>
                  <v-card-title>Sufixo</v-card-title>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="form.sufixo"
                    label="Sufixo"
                    :items="fields"
                    item-text="label"
                    item-value="name"
                    dense
                    hint="Insira um texto para ser exibido após o campo ou selecione um campo existente"
                    allow-overflow
                    :hide-no-data="false"
                    no-data-text="Pressione enter para adicionar esse texto"
                    @keydown.enter.prevent="addCustomOption"
                    @blur="addCustomOption"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col v-if="component.functions" cols="12">
                  <v-divider horizontal></v-divider>
                  <v-card-title>Funções</v-card-title>
                </v-col>
                <v-col v-if="component.functions">
                  <Functions
                    v-model="functions"
                    :functions="component.functions"
                  />
                </v-col>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn v-if="!form.name" color="green" text @click="create"
                >Criar</v-btn
              >
              <v-btn v-else color="green" text @click="update">Atualizar</v-btn>
              <v-btn color="red" text @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import StructureService from '../../../services/Structure'
import Structure from '../../../services/StructureReport/Structure'
import {
  CREATE_COMPONENT,
  CREATE_REPORT,
  DELETE_ITEM,
  LOAD_ALL_FIELDS,
  UPDATE_COMPONENT,
} from '../../../vuex/modules/structure'
import Components from './components/index.vue'
import Rules from './rules.vue'
import Functions from './StructureFunctions.vue'

export default {
  name: 'NewStructure',

  components: {
    Rules,
    Components,
    Functions,
  },

  props: {
    structureItem: {
      type: Object,
      required: true,
    },

    projectStructure: {
      type: Object,
      default: () => ({}),
    },

    fields1: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    Structure,
    dialog: false,

    component: null,

    form: {
      title: '',
      placeholder: '',
      masks: '',
      sufixo: '',
    },

    params: {},
    rules: [],
    functions: [],
    fields: [],

    actualField: [],
    selectedStep: {},
    reservedField: {},

    notDeletedFields: [
      'fazenda',
      'dataAtendimento',
      'horaInicio',
      'nomeConsultor',
    ],
  }),

  computed: {
    ...mapState('structure', [
      'data',
      'loading',
      'project',
      'historyOpenedIems',
    ]),

    items() {
      return this.Structure.map(item => item.label)
    },

    structureItems() {
      const component = this.Structure.find(
        item => item.componentType === this.structureItem.componentType,
      )

      const components = component?.properties?.components ?? []

      if (this.dialog && components.length && !this.component) {
        return this.Structure.filter(item =>
          components.includes(item.componentType),
        )
      }

      return this.Structure
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.form = {
          title: '',
          postion: 1,
        }
      }
    },

    historyOpenedIems: {
      handler(value) {
        this.actualField = value[value.length - 1]

        if (this.actualField?.id) {
          this.selectedStep = this.actualField
        }

        if (this.structureItem?.id) {
          this.selectedStep = this.structureItem
        }
      },

      immediate: true,
    },

    structureItem: {
      handler(value) {
        if (value?.id) {
          this.selectedStep = value
        }
      },

      immediate: true,
    },
  },

  created() {
    this.LOAD_ALL_FIELDS().then(fields => {
      this.fields = fields
    })
  },

  methods: {
    ...mapActions('structure', [
      CREATE_REPORT,
      CREATE_COMPONENT,
      UPDATE_COMPONENT,
      DELETE_ITEM,
      LOAD_ALL_FIELDS,
    ]),

    chosenComponent(input) {
      this.component = this.Structure.find(item => item.componentType === input)
    },

    create() {
      if (!this.component || !this.form.title) {
        return Swal.fire({
          title: 'Preencha todos os campos',
          icon: 'warning',
        })
      }

      const type = this.form.type ?? this.component.type ?? 'default'

      const form = {
        ...this.form,
        type,
      }

      const json = StructureService.generateJSONComponent({
        structure: this.component,
        form,
        mask: this.form.masks ?? 'default',
        params: this.params,
        rules: this.rules,
        functions: this.functions,
        fields: this.fields,
      })

      this.CREATE_COMPONENT({
        component: json,
        projectStructure: this.projectStructure,
        structureItem: this.structureItem,
        step: this.actualField,
        form,
      })

      this.dialog = false
    },

    update() {
      if (!this.component || !this.form.title) {
        return Swal.fire({
          title: 'Preencha todos os campos',
          icon: 'warning',
        })
      }

      const json = StructureService.generateJSONComponent({
        structure: this.component,
        form: this.form,
        mask: this.form.masks ?? 'default',
        params: this.params,
        rules: this.rules,
        functions: this.functions,
        fields: this.fields,
      })

      this.UPDATE_COMPONENT({
        component: json,
        projectStructure: this.projectStructure,
        structureItem: this.structureItem,
        form: this.form,
      })

      this.dialog = false
    },

    openUpdate() {
      this.dialog = true

      this.component = this.Structure.find(
        item => item.componentType === this.structureItem.componentType,
      )

      this.form = {
        title: this.structureItem.label,
        placeholder: this.structureItem.placeholder,
        masks: this.structureItem.mask,
        name: this.structureItem.name,
        type: this.structureItem.type ?? this.component.type ?? 'default',
      }

      const rules = []

      this.structureItem.rules.forEach(rule => {
        rules[rule.key] = rule.value
      })

      this.rules = rules
    },

    deleteItem(structureItem) {
      if (this.structureItem?.id == '1') {
        return Swal.fire({
          title: 'Você não pode deletar esta step',
          text: 'Esta step é obrigatória',
          icon: 'warning',
        })
      }

      if (this.notDeletedFields.includes(structureItem?.name)) {
        return Swal.fire({
          title: 'Você não pode deletar este campo',
          text: `O campo ${structureItem.label} é obrigatório`,
          icon: 'warning',
        })
      }

      Swal.fire({
        title: 'Você tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar!',
      }).then(result => {
        if (result.isConfirmed) {
          this.DELETE_ITEM({
            item: structureItem,
            step: this.selectedStep,
          })
        }
      })
    },

    openDialogComponent() {
      this.form = {
        title: '',
        placeholder: '',
        masks: '',
      }
      this.component = null
      this.dialog = true
    },

    addCustomOption(item) {
      const value = item.target.value

      if (value) {
        this.fields.push({ label: value, name: value })
        this.form.sufixo = value
      }
    },
  },
}
</script>
