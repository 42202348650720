<template>
  <v-container>
    <v-card>
      <base-loading v-if="historyLoading" />
      <crud-list
        v-else
        v-model="structuresHistory"
        :headers="headers"
        :actions="actions"
        :slots="['item.deletedAt']"
      >
        <template v-slot:[`item.deletedAt`]="{ item }">
          {{ moment(item.deletedAt).format('LLL') }}
        </template>
      </crud-list>
    </v-card>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import { ROLLBACK } from '../../../vuex/modules/structure'
export default {
  name: 'HistoryList',

  data() {
    return {
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Relatório',
          align: 'center',
          sortable: true,
          value: 'title',
          width: '120px',
          filterable: true,
        },
        {
          text: 'Exclusão',
          align: 'center',
          sortable: true,
          value: 'deletedAt',
          width: '120px',
          filterable: true,
        },
      ],

      actions: [
        {
          text: 'Reversão',
          icon: 'mdi-undo',
          color: 'primary',
          click: item => this.rollback(item),
        },
      ],
    }
  },

  computed: {
    ...mapState('structure', ['data', 'structuresHistory', 'historyLoading']),

    isEnableSaveButton() {
      return this.projectSelected && this.isModified ? true : false
    },
  },

  methods: {
    ...mapActions('structure', [ROLLBACK]),

    rollback(item) {
      Swal.fire({
        title: 'Deseja reverter a exclusão?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(result => {
        if (result.isConfirmed) {
          this.ROLLBACK({ id: item.id })
        }
      })
    },
  },
}
</script>
