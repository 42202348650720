<template>
  <v-row>
    <v-col v-for="func in functions" :key="func.key" :cols="func?.cols ?? 4">
      <v-autocomplete
        v-if="func.type == 'inputSelect'"
        v-model="form[func.key]"
        :label="func.label"
        :items="fieldsByType(func?.properties?.componentType)"
        :hint="func.description"
        item-text="label"
        item-value="name"
        required
        dense
        clearable
        :multiple="func.properties?.multiple ?? false"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index < 3" small class="caption ml-1">
            {{
              item.label +
              `${
                !func.properties?.multiple ||
                form[func.key].length == 1 ||
                index == form[func.key].length - 1
                  ? ''
                  : ', '
              }`
            }}</span
          >
          <span v-if="index === 3" class="grey--text caption">
            (+{{ form[func.key].length - 3 }} outros)
          </span>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Compoents from '../../../services/StructureReport/Structure'
import { LOAD_ALL_FIELDS } from '../../../vuex/modules/structure'
export default {
  name: 'Functions',

  props: {
    functions: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      Compoents,

      form: {},

      fields: [],
    }
  },

  computed: {
    ...mapState('structure', ['data', 'loading', 'project']),
  },

  watch: {
    value: {
      handler() {
        this.form = this.value
      },
      immediate: true,
    },

    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },

  created() {
    this.getFields()
  },

  methods: {
    ...mapActions('structure', [LOAD_ALL_FIELDS]),

    async getFields() {
      this.fields = await this.LOAD_ALL_FIELDS()
    },

    getFieldsByComponentType(componentType) {
      return this.fields.filter(field => field.componentType === componentType)
    },

    fieldsByType(type) {
      if (!type) return this.fields

      return this.fields.filter(field => field.componentType === type)
    },
  },
}
</script>
