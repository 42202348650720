export const incrementBy = {
  key: 'incrementBy',
  label: 'Incrementar',
  description: 'Incrementa um valor por outros campos de tipo numérico',
  type: 'inputSelect',
  cols: 6,
  properties: {
    inputSelectType: 'number',
    multiple: true,
    componentType: 'inputText',
  },
}

export default {
  incrementBy,
}
