<template>
  <v-row>
    <v-col v-for="rule in rules" :key="rule.key" :cols="rule?.cols ?? 4">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-checkbox
              v-if="rule.type == 'checkbox'"
              v-model="form[rule.key]"
              :label="rule.label"
              color="primary"
              required
              dense
            />
          </span>
        </template>
        <span>{{ rule.description }}</span>
      </v-tooltip>
      <v-text-field
        v-if="rule.type == 'number'"
        v-model="form[rule.key]"
        :label="rule.label"
        :hint="rule.description"
        required
        clearable
        type="number"
        dense
      ></v-text-field>
      <v-autocomplete
        v-if="rule.type == 'inputSelect'"
        v-model="form[rule.key]"
        :label="rule.label"
        :items="fieldsByType(rule?.properties?.inputSelectType)"
        :hint="rule.description"
        item-text="label"
        item-value="name"
        required
        dense
        clearable
        :multiple="rule.properties?.multiple ?? false"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index < 3" small class="caption ml-1">
            {{
              item.label +
              `${
                !rule.properties?.multiple ||
                form[rule.key].length == 1 ||
                index == form[rule.key].length - 1
                  ? ''
                  : ', '
              }`
            }}</span
          >
          <span v-if="index === 3" class="grey--text caption">
            (+{{ form[rule.key].length - 3 }} outros)
          </span>
        </template>

        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <v-row v-if="rule.type == 'minimumTimePiker'">
        <v-col cols="12"><span class="caption">Data e Hora mínima</span></v-col>

        <v-col cols="6">
          <v-autocomplete
            v-model="form['minimumDate']"
            label="Campo de data mínima"
            :items="getFieldsByComponentType('datePiker')"
            :hint="rule.description"
            item-text="label"
            item-value="name"
            required
            dense
            clearable
          />
        </v-col>

        <v-col cols="6">
          <v-autocomplete
            v-model="form['minimumTime']"
            label="Campo de hora mínima"
            :items="getFieldsByComponentType('timePiker')"
            :hint="rule.description"
            item-text="label"
            item-value="name"
            required
            dense
            clearable
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form['time']"
            v-mask="'##:##:##'"
            :label="rule.label"
            placeholder="(HH:MM:SS)"
            hint="Tempo em horas, minutos e segundos "
            required
            clearable
            dense
          />
        </v-col>
      </v-row>

      <v-row v-if="rule.key == 'defaultValue'">
        <v-col>
          <v-select
            v-if="rule.type == 'inputOptions'"
            v-model="form[rule.key]"
            :label="rule.label"
            :items="rule.options"
            :hint="rule.description"
            item-text="label"
            item-value="key"
            required
            dense
            clearable
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row v-if="rule.key == 'fieldCalculated'">
        <Calculate v-model="form[rule.key]" />
      </v-row>

      <v-row v-if="rule.key == 'text'">
        <v-col v-if="textLoading">
          <p class="caption">
            Colocar entr {} o nome do componente, exemplo: {Valor da visita}
          </p>
          <TiptapVuetify
            v-model="form[rule.key]"
            class="tiptap"
            placeholder="Digite o texto"
            :extensions="extensions"
            :toolbar-attributes="{ color: 'primary', dark: true }"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  HardBreak,
  History,
  HorizontalRule,
  Italic,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  TiptapVuetify,
  Underline,
} from 'tiptap-vuetify'
import { mapActions, mapState } from 'vuex'
import Compoents from '../../../services/StructureReport/Structure'
import { LOAD_ALL_FIELDS } from '../../../vuex/modules/structure'
import Calculate from './components/Calculate.vue'

export default {
  name: 'Rules',

  components: {
    Calculate,
    TiptapVuetify,
  },

  props: {
    rules: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      Compoents,
      textLoading: false,

      form: {},

      fields: [],

      extensions: [
        History,
        Blockquote,

        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        Bold,
        HorizontalRule,
        Paragraph,
        HardBreak,
        Code,
      ],
    }
  },

  computed: {
    ...mapState('structure', ['data', 'loading', 'project']),
  },

  watch: {
    value: {
      handler() {
        this.form = this.value
      },
      immediate: true,
    },

    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },

  created() {
    this.getFields().then(() => {
      this.listFieldsInText()
    })
  },

  methods: {
    ...mapActions('structure', [LOAD_ALL_FIELDS]),

    async getFields() {
      this.fields = await this.LOAD_ALL_FIELDS()
    },

    getFieldsByComponentType(componentType) {
      return this.fields.filter(field => field.componentType === componentType)
    },

    fieldsByType(type) {
      if (!type) return this.fields

      return this.fields.filter(field => field.type === type)
    },

    listFieldsInText() {
      this.textLoading = false

      const rule = this.rules.find(rule => rule.key === 'text')

      if (!rule) {
        this.textLoading = false
        return
      }

      const keys = this.value.value

      if (!keys) {
        this.textLoading = false
        return
      }

      const fields = this.fields
        .filter(field => keys.includes(field.name))
        .map(field => {
          if (!field.label) {
            return {
              name: field.name,
              label:
                '<code style="color: #c24f4f">Titulo do campo não encontrado!</code>',
            }
          }
          return {
            name: field.name,
            label: field.label,
          }
        })

      let text = this.value.text

      fields.forEach(field => {
        const find = `{${field.name}}`
        text = text.replaceAll(find, `{${field.label}}`)
      })

      this.form.text = text
      this.textLoading = true

      return fields
    },
  },
}
</script>

<style>
.tiptap {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  transition: border-color 0.2s;
}
.tiptap .ProseMirror {
  padding: 4px;
}
</style>
